body {
  background-color: #f5f5f5;
}

select {
  color: #000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.button-group button {
  margin-right: 5px;
  /* margin-bottom: 5px; */
  height: 40px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}
.button-group .div-box {
  margin-right: 5px;
  box-sizing: border-box;
  height: 40px;
  padding: 5px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}
.button-group select {
  margin-right: 5px;
  height: 40px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.editor-controls {
  position: relative;
  margin-bottom: 10px;
}

.editor-controls button {
  margin-right: 5px;
  margin-bottom: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.editor-controls button:hover {
  background-color: #e0e0e0;
}

.editor-controls select {
  margin-right: 5px;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
}

.editor-controls select:hover {
  background-color: #e0e0e0;
}

.editor-wrapper {
  overflow-x: scroll;
  display: flex;
}

.editor-container {
  width: 794px; /* A4 용지 너비  */
  min-width: 794px;
  margin: 0;
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.chrome-picker {
  font-family: inherit !important;
}

/* .chrome-picker .flexbox-fix:nth-child(4) {
  display: none !important;
} */

.icon-button {
  font-family: "Fontello", "Noto Sans", "Noto Sans KR", "Noto Sans JP",
    sans-serif;
  font-size: 24px;
  width: 40px;
  height: 40px;
  margin: 2px;
  padding: 5px;
  color: #000;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background-color: #e0e0e0;
}

.DraftEditor-root .public-DraftStyleDefault-block span[data-offset-key] {
  font-family: inherit;
  font-weight: inherit;
}

.button-container {
  margin-top: 20px; /* 버튼과 에디터 사이의 여백 */
  display: flex; /* 버튼을 가로로 배치 */
  justify-content: center; /* 중앙 정렬 */
}

.button-container button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #b0c4de;
  color: rgb(0, 0, 0);
  transition: background-color 0.3s;
}

.centered-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tab-menu {
  display: flex;
  flex-direction: column;
  font-family: "Fontello", "Noto Sans", "Noto Sans KR", "Noto Sans JP",
    sans-serif;
}

.button-group button.is-active {
  background-color: #ccc;
}

.tab-menu button {
  color: #000;
  height: 40px;
  min-width: fit-content;
  padding: 5px 10px;
  margin: 2px;
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}

.tab-menu button.active {
  background-color: #b0c4de;
  color: rgb(0, 0, 0);
}

.ttf-characters-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 794px;
  justify-content: center;
  /* background-color: #ccc; */
  margin-bottom: 5px;
}

.ttf-characters {
  display: flex;
  flex-wrap: wrap;
  /* margin-bottom: 10px; */
}
